



















































































import { Component, Vue } from 'vue-property-decorator';
import { currencyFormat } from '@/vue-app/utils/currency';
import PositionViewModel from '@/vue-app/view-models/allianz-dashboard/position-view-model';

@Component({
  name: 'Position',
  components: {
    LoadingDataCard: () => import('@/vue-app/components/home-dashboard/LoadingDataCard.vue'),
  },
  filters: { currencyFormat },
})
export default class Position extends Vue {
  position_view_model = Vue.observable(new PositionViewModel(this));

  created() {
    this.position_view_model.initialize();
  }
}
