import TYPES from '@/types';

import { currencyFormat } from '@/vue-app/utils/currency';
import Vue from 'vue';

// Application
import {
  GetAllianzAccountQuery,
} from '@/modules/my-investment/allianz/allianz-account/application/queries';
import {
  SearchAllianzAccountReportQuery,
} from '@/modules/my-investment/allianz/allianz-account-report/application/queries';
import AccessMoreStrategiesStateManagerService
  from '@/modules/my-investment/allianz/access-more-strategies/application/services/access-more-strategies-state-manager-service';
import GetInvestorProfileQuery
  from '@/modules/flagship/investor-profile/investor-profile/application/queries/get-investor-profile-query';

// Domain
import AccessMoreStrategiesState from '@/modules/my-investment/allianz/access-more-strategies/domain/state/access-more-strategies-state';
import {
  AllianzAccountReportEntity,
} from '@/modules/my-investment/allianz/allianz-account-report/domain/entities/allianz-account-report-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class PositionViewModel {
  readonly i18n_namespace = 'components.allianz-dashboard.position';

  @Inject(TYPES.GET_ALLIANZ_ACCOUNT_QUERY)
  private readonly get_allianz_account_query!: GetAllianzAccountQuery;

  @Inject(TYPES.SEARCH_ALLIANZ_ACCOUNT_REPORT_QUERY)
  private readonly search_allianz_account_report_query!: SearchAllianzAccountReportQuery;

  @Inject(TYPES.GET_INVESTOR_PROFILE_QUERY)
  private readonly get_investor_profile_query!: GetInvestorProfileQuery;

  @Inject(TYPES.ACCESS_MORE_STRATEGIES_STATE_MANAGER_SERVICE)
  private readonly manager_service!: AccessMoreStrategiesStateManagerService;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  private readonly customer_id = sessionStorage.getItem('user_id');

  readonly dci_urls = [
    {
      key: 'swsrfp',
      value: `${process.env.VUE_APP_CANVA_URL}/design/DAFlWsKBoRI/RWX4FL1icwk04EZGzpBnJA/view?utm_content=DAFlWsKBoRI&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink#1`,
      label: 'DCI SWS Pesos',
    },
    {
      key: 'swsmod',
      value: `${process.env.VUE_APP_CANVA_URL}/design/DAFqUDdg7hE/fVwv7aLbY-uLtLhRpASgbw/view?utm_content=DAFqUDdg7hE&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink`,
      label: 'DCI SWS Moderado',
    },
  ];

  readonly view: Vue;

  is_loading = true;

  total_value = 0.0;

  investment_options = [
    {
      name: '',
      amount: 0.0,
      amount_in_dollars: 0.0,
      color: '',
      percent: 0,
      found_code: '',
      dci_url: '',
      text_button_dci: '',
    },
  ];

  chart_options = {
    series: [0],
    labels: [''],
    colors: ['#0047BA', '#5BCFDE', '#132C54', '#E9E8ED', '#E65C61'],
    chart: {
      type: 'donut',
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
  };

  positions: Array<AllianzAccountReportEntity> = [];

  show_button_access_more_strategies = false;

  hire_moderate = {
    show: false,
    show_successfully: false,
    investor_goal_id: '',
    goal_name: '',
  }

  state: AccessMoreStrategiesState;

  public constructor(view: Vue) {
    this.state = this.manager_service.getAccessMoreStrategiesState();
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  loadInvestorProfile = async () => {
    const investor_profile = await this.get_investor_profile_query.execute(false);
    this.show_button_access_more_strategies = !investor_profile.goals_completed;
  }

  loadAllianzAccountReport = async () => {
    try {
      this.positions = await this.search_allianz_account_report_query.execute(this.customer_id);
      if (this.positions.length) {
        this.fillData();
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_position'));
    }
  }

  fillData() {
    this.total_value = 0;
    let pos = 0;

    this.chart_options.series.length = 0;
    this.chart_options.labels.length = 0;
    this.investment_options.length = 0;
    this.positions.forEach((position) => {
      if (position.found_code === 'SWSRFP') {
        if (Number(position.final_balance_mxn) > 500) {
          this.state.available_balance = Number(position.final_balance_mxn);
          this.state.price_by_unit = position.units_value || 0.00;
        }
      }

      if (Number(position.final_balance_mxn) > 0) {
        this.total_value += position.final_balance_mxn || 0;
        this.chart_options.series.push(Number(position.final_balance_mxn));
        this.chart_options.labels.push(position.found_name!);
      }
    });

    const array_colors_length = this.chart_options.colors.length - 1;
    this.positions.forEach((position) => {
      if (Number(position.final_balance_mxn) > 0) {
        const dci_option = this.getDciOption(position.found_code!);
        this.investment_options.push({
          name: position.found_name!,
          amount: Number(position.final_balance_mxn),
          amount_in_dollars: Number(position.final_balance),
          percent: Number(((position.final_balance_mxn! / this.total_value) * 100)
            .toFixed(2)),
          color: this.chart_options.colors[pos],
          found_code: position.found_code!,
          dci_url: dci_option.value,
          text_button_dci: dci_option.label,
        });
        pos = (pos >= array_colors_length) ? 0 : pos += 1;
      }
    });
  }

  getDciOption = (fund_code: string) => {
    let dci_option = {
      key: '',
      value: '',
      label: '',
    };
    const dci_url_founded = this.dci_urls.find(
      (dci_url) => dci_url.key === fund_code.toLowerCase(),
    );
    if (dci_url_founded) {
      dci_option = dci_url_founded;
    }
    return dci_option;
  }

  showDCI = (dci_url: string) => {
    window.open(dci_url, '_blank');
  }

  formatAsCurrency = (amount: number) => currencyFormat(amount);

  showAccessMoreStrategies = () => {
    this.view.$emit('show');
  }

  initialize = async () => {
    await this.loadAllianzAccountReport();
    await this.loadInvestorProfile();
    this.is_loading = false;
  }
}
